<template>
  <div>
    <v-select
      data-test="test-selector-specialty"
      v-model="filter.specialtyId"
      :label="`${$t('medicalTests.specialty')}`"
      outlined
      dense
      hide-details
      class="mb-2"
      :items="activeSpecialities"
      item-text="description"
      item-value="id"
      :loading="isLoadingSpecialties"
      :disabled="!activeSpecialities.length"
      clearable
      @click:clear="cleanSpecialty"
    />
    <v-select
      data-test="test-selector-group"
      v-model="filter.groupId"
      :label="`${$t('medicalTests.group')}`"
      outlined
      dense
      hide-details
      class="mb-2"
      :items="activeGroups"
      item-text="description"
      item-value="id"
      :loading="isLoadingGroups"
      :disabled="!activeGroups.length"
    />
    <v-select
      data-test="test-selector-collection"
      v-model="filter.collectionId"
      :label="`${$t('medicalTests.collection')}`"
      outlined
      dense
      hide-details
      class="mb-2"
      :items="activeCollections"
      item-text="description"
      item-value="id"
      :loading="isLoadingCollections"
      :disabled="!activeCollections.length"
    />
    <v-select
      data-test="test-selector-subgroup"
      v-model="filter.subgroupId"
      :label="`${$t('medicalTests.subgroup')}`"
      outlined
      dense
      hide-details
      class="mb-2"
      :items="activeSubGroups"
      item-text="description"
      item-value="id"
      :loading="isLoadingSubGroups"
      :disabled="!activeSubGroups.length"
    />
    <v-text-field
      data-test="test-input-search"
      v-model="filter.query"
      filled
      flat
      rounded
      dense
      clearable
      prepend-inner-icon="mdi-magnify"
      :placeholder="$t('medicalTests.search')"
      hide-details
      class="mb-3"
    />
    <div class="d-flex flex-column overflow-y-auto" :style="{ minHeight: minHeight, maxHeight: maxHeight }">
      <v-checkbox
        v-for="(act, index) in activeActs"
        :key="index"
        v-model="selectedActs"
        class="act"
        hide-details
        :value="act"
        :label="`${act.code} - ${act.description}`"
      />
    </div>
    <div class="text-center mt-3">
      <v-pagination v-model="page" :length="paginationLength" circle />
    </div>
  </div>
</template>

<script>
import { useNomenclator } from '@/use/MedicalTests/useNomenclator';
import { watch } from 'vue';

export default {
  props: {
    minHeight: {
      type: String,
      default: 'inherit',
    },
    maxHeight: {
      type: String,
      default: '400px',
    },
  },
  setup: function(props, { emit }) {
    const {
      filter,
      activeSpecialities,
      activeGroups,
      activeCollections,
      activeSubGroups,
      activeActs,
      isLoading,
      isLoadingSpecialties,
      isLoadingGroups,
      isLoadingCollections,
      isLoadingSubGroups,
      isLoadingActs,
      page,
      pageCount,
      itemsPerPage,
      acts,
      loadSpecialties,
      loadGroups,
      loadCollections,
      loadSubGroups,
      loadActs,
      cleanSpecialty,
      selectedActs,
      paginationLength,
      refreshActs,
    } = useNomenclator(JSON.parse(window.localStorage.getItem('auth_ehealth')).user.professional_id, true);

    refreshActs.value = true;
    loadSpecialties();
    loadActs();

    watch(
      () => selectedActs.value,
      currentValue => {
        return emit('input', currentValue);
      }
    );

    return {
      filter,
      activeSpecialities,
      activeGroups,
      activeCollections,
      activeSubGroups,
      activeActs,
      isLoading,
      isLoadingSpecialties,
      isLoadingGroups,
      isLoadingCollections,
      isLoadingSubGroups,
      isLoadingActs,
      acts,
      loadGroups,
      loadCollections,
      loadSubGroups,
      loadActs,
      cleanSpecialty,
      page,
      pageCount,
      itemsPerPage,
      selectedActs,
      paginationLength,
    };
  },
};
</script>
